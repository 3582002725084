<template>
  <div class="managerUserOrderView">
    <!-- <el-card> -->
      <!-- <div class="viewBtnBox"> -->
        <!-- <el-button type="primary" size="mini" @click="handleClick('view', scope.row)" >{{$t('button.view')}}</el-button> -->
        <!-- <el-button type="primary" size="mini" @click="handleClick('edit')" >{{$t('button.edit')}}</el-button>
        <el-button type="primary" size="mini" @click="deleteClick" >{{$t('button.delete')}}</el-button>
        <el-button type="primary" size="mini" @click="handleClick('copy')" >{{$t('button.copy')}}</el-button> -->
        <!-- <div v-if="modifyData && modifyData.data && modifyData.data.member_actions && modifyData.data.member_actions.custom_actions">
          <el-button v-for="(item, index) in modifyData.data.member_actions.custom_actions" :key="'custom_actions' + index" type="primary" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </div> -->
      <!-- </div>
    </el-card> -->
    <!-- <showForm :showFormData="showFormData" :data="modifyData.obj"></showForm> -->
    <view-frame :titleObject="titleObject" :buttonObj="buttonObj">
      <template #rightBtn="scope">
        <template v-if="custName && custName.member_actions && custName.member_actions.default_actions">
          <el-button v-show="item.action !== 'view'" v-for="(item, index) in custName.member_actions.default_actions" :key="'default_actions' + index" type="primary" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
        </template>
        <template v-if="custName && custName.member_actions && custName.member_actions.custom_actions">
          <el-button v-for="(item, index) in custName.member_actions.custom_actions" :key="'custom_actions' + index" type="primary" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </template>
      <template>
        <el-card>
          <el-row>
            <el-col :span="24">
            <span class="view__text">{{$t('form.order.orderInfo')}}</span>
            <el-card class="animate__animated animate__fadeInDown" shadow="hover">
              <custome-table tableType="object" :custSpan="6" :data="custName.order_information" :dataItem="orderViewItem" @forwardToPage="forwardToPage"></custome-table>
            </el-card>
            </el-col>
          </el-row>
          <el-row class="margintop20" :gutter="20">
            <el-col :span="12">
              <span class="view__text">{{$t('form.order.custInfo')}}</span>
              <el-card class="animate__animated animate__fadeInLeft" shadow="hover">
                <custome-table tableType="object" :custSpan="8" :data="custName.customer_information" :dataItem="CustomerViewItem"></custome-table>
              </el-card>
            </el-col>
            <el-col :span="12" v-if="custName.delivery_information">
              <span class="view__text">{{$t('form.order.deliverInfo')}}</span>
              <el-card class="animate__animated animate__fadeInRight" shadow="hover">
                <custome-table tableType="object" :custSpan="12" :data="custName.delivery_information" :dataItem="deliveryViewItem"></custome-table>
              </el-card>
            </el-col>
            <el-col :span="12" v-if="custName.collection_information">
              <span class="view__text">{{$t('form.order.collectionInfo')}}</span>
              <el-card class="animate__animated animate__fadeInRight" shadow="hover">
                <custome-table tableType="object" :custSpan="12" :data="custName.collection_information" :dataItem="collectionViewItem"></custome-table>
              </el-card>
            </el-col>
          </el-row>
          <el-row class="margintop20" :gutter="20">
            <el-col :span="12">
              <span class="view__text">{{$t('form.order.hostInfo')}}</span>
              <el-card class="animate__animated animate__fadeInRight" shadow="hover">
                <custome-table tableType="object" :custSpan="12" :data="custName.host_information" :dataItem="host_information" @forwardToPage="forwardToPage"></custome-table>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row :gutter="20">
            <el-col :span="12">
              <span class="view__text">{{$t('form.order.amountInfo')}}</span>
              <el-card class="animate__animated animate__fadeInLeft" shadow="hover">
                <custome-table tableType="array" :custSpan="24" :dataItem="custName.amount_information"></custome-table>
              </el-card>
            </el-col>
             <el-col :span="12">
              <span class="view__text">{{$t('form.order.logisticInfo')}}</span>
              <el-card class="animate__animated animate__fadeInLeft" shadow="hover">
                <custome-table tableType="object" :custSpan="12" :dataItem="logisticViewItem" :data="custName.logistic_information"></custome-table>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.order.items')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="tableDataViewItem" :operateVisible="true" :tableData="custName.shop_payment_items" :operationColWidth="60" @operate="handleClick" @forwardToPage="handleOrderItemsTableForwardToPage">
                   <template #rightBtn="scope">
                      <template v-if="scope.row && scope.row.member_actions && scope.row.member_actions.default_actions">
                        <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
                      </template>
                      <template v-if="scope.row && scope.row.member_actions && scope.row.member_actions.custom_actions">
                        <el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row, 'del')">{{item.name}}</el-button>
                      </template>
                  </template>
                 </createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.common.changeHistory')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="historyTableItem" :operateVisible="false" :tableData="historyTableData"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </template>
    </view-frame>
    <dialogEffect v-if="dialogVisible" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
    <el-dialog
      :title="$t('form.order.romoveProduct')"
      :visible.sync="dialog.delOderItem.visible"
      width="500px">
      <div class="del-oder-item-dialog-body">
        <div class="heder">
          <el-avatar class="avatar" shape="square" :size="80" fit="cover" :src="dialog.delOderItem.data && dialog.delOderItem.data.image.value"></el-avatar>
          <div class="info-box">
            <div class="title">{{ $t('form.order.productName') }}: {{ dialog.delOderItem.data && dialog.delOderItem.data.shop_item.value.clickables[0].name }}</div>
            <div class="title">{{ $t('form.order.sku') }}: {{ dialog.delOderItem.data && dialog.delOderItem.data.sku.value }}</div>
          </div>
        </div>
        <div class="notice">{{ $t('form.order.removeProducNotice') }}</div>
        <el-form ref="delOderItemDialogForm" :model="dialog.delOderItem.formData" :rules="dialog.delOderItem.formDataRules" label-width="80px" label-position="left">
          <el-form-item :label="$t('form.order.currentCount')">{{ dialog.delOderItem.data && dialog.delOderItem.data.count.value }}</el-form-item>
          <el-form-item :label="$t('form.order.romveCount')" prop="quantity">
            <el-input-number v-model="dialog.delOderItem.formData.quantity" :min="1" :max="dialog.delOderItem.data && dialog.delOderItem.data.count.value"></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="handleDelOderItemDialogCancelClick">{{ $t('button.cancelbig') }}</el-button>
        <el-button type="primary" @click="handleDelOderItemDialogOkClick">{{ $t('button.determine') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import { getDineinOrderDetails } from '@/services/order'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import dialogEffect from '@/components/dialogEffect'
import jsonData from './jsonData'
import request from '@/utils/request'
import { mixin } from '@/mixins/viewDiolog'

export default {
  components: {
    createTable,
    viewFrame,
    customeTable,
    dialogEffect
  },
  mixins: [mixin],
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {
        show: true
      },
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      host_information: jsonData.host_information,
      orderViewItem: jsonData.orderViewItem,
      CustomerViewItem: jsonData.CustomerViewItem,
      deliveryViewItem: jsonData.deliveryViewItem,
      amountViewItem: jsonData.amountViewItem,
      tableDataViewItem: jsonData.tableDataViewItem,
      collectionViewItem: jsonData.collectionViewItem,
      historyTableItem: history.historyTableItem,
      logisticViewItem: [],
      // dialogData: [],
      // batchData: [],
      // dialogVisible: false,
      dialog: {
        delOderItem: {
          visible: false,
          data: null,
          formDataRules: {
            quantity: [
              { required: true, message: this.$t('text.required'), trigger: 'change' }
            ]
          },
          formData: {
            quantity: 1
          }
        }
      },
      historyTableData: []
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    this.validateVisible = false
    // const dataOperate = JSON.parse(sessionStorage.getItem('dataOperate'))
    // if (to.name === 'managerProductEdit') {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: dataOperate.id, type: 'edit', obj: dataOperate.obj }))
    // }
    // if (to.name === 'managerProductCopy') {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: dataOperate.id, type: 'copy', obj: dataOperate.obj }))
    // }
    // if (!(to.name === 'managerProductEdit' || to.name === 'managerProductCopy')) {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: undefined, type: undefined, obj: undefined }))
    // }
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: undefined, type: undefined, obj: undefined }))

    next()
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    async init () {
      // const dataOperate = JSON.parse(sessionStorage.getItem('userOrderdataOperate'))
      // this.modifyData = dataOperate
      // // console.log(this.modifyData)
      // const { obj, type, id } = dataOperate
      // // Object.assign(this.titleObject, { title: this.$t('form.order.order') + ' No. ', data: '' })
      // Object.assign(this.titleObject, { title: this.$t('form.order.order') + ' No. ', data: obj.order_number ? obj.order_number : 0 })
      // this.custName = obj
      // this.type = type
      this.$store.state.app.loading = this.$loading(loading)
      const res = await getDineinOrderDetails(this.$route.params.id)
      if (res.status === 200) {
        this.custName = res.data.shop_payment
        Object.assign(this.titleObject, { title: this.$t('form.order.order') + ' No. ', data: this.custName.order_number ? this.custName.order_number : 0 })
      }
      this.$store.state.app.loading.close()
      // if (this.custName && this.custName.shop_payment_items && this.custName.shop_payment_items.length > 0) {
      //   Object.keys(this.custName.shop_payment_items[0]).forEach(item => {
      //     const index = this.tableDataViewItem.findIndex(fditem => fditem.prop === item)
      //     if (index !== -1) this.tableDataViewItem[index].label = this.custName.shop_payment_items[0][item].name
      //   })
      // }
      // if (this.custName && this.custName.logistic_information && Object.keys(this.custName.logistic_information).length > 0) {
      //   Object.keys(this.custName.logistic_information).forEach(key => {
      //     this.logisticViewItem.push({
      //       prop: key
      //     })
      //   })
      // }
      // //  获取历史操作
      // getHistoryList({ item_type: 'ShopPayment', item_id: this.$route.params.id }).then(res => {
      //   if (res.status === 200) {
      //     this.historyTableData = res.data.versions
      //   }
      // })
      this.getDetail()
      // if (Object.keys(obj).length > 0) {
      //   Object.keys(obj).forEach(res => {
      //     const name = []
      //     res.split('_').forEach(item => {
      //       name.push(item.slice(0, 1).toUpperCase() + item.slice(1))
      //     })
      //     Object.assign(this.custName, { [name.join(' ')]: obj[res] })
      //   })
      // }
      // console.log(this.custName)
      // this.buttonForm = dataOperate.data
    },
    getDetail () {
      if (this.custName && this.custName.shop_payment_items && this.custName.shop_payment_items.length > 0) {
        Object.keys(this.custName.shop_payment_items[0]).forEach(item => {
          const index = this.tableDataViewItem.findIndex(fditem => fditem.prop === item)
          if (index !== -1) this.tableDataViewItem[index].label = this.custName.shop_payment_items[0][item].name
        })
      }
      if (this.custName && this.custName.logistic_information && Object.keys(this.custName.logistic_information).length > 0) {
        Object.keys(this.custName.logistic_information).forEach(key => {
          this.logisticViewItem.push({
            prop: key
          })
        })
      }
      //  获取历史操作
      getHistoryList({ item_type: 'ShopPayment', item_id: this.$route.params.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },
    // handleCusClick (data) {
    //   this.batchData = data
    //   if (data.extra_params && data.extra_params.length > 0) {
    //     this.dialogData = data.extra_params
    //     this.dialogVisible = true
    //   } else {
    //     Utils.custOperate(data, this)
    //   }
    // },
    // doNextConfirm (data) {
    //   Utils.custOperate(this.batchData, this, {}, data)
    // },
    async dialogSearch () {
      this.init()
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      // const res = await getDineinOrderDetails(this.$route.params.id)
      // // loading.close()
      // if (res.status === 200) {
      //   // sessionStorage.setItem('userOrderdataOperate', JSON.stringify({ id: this.modifyData.id, type: this.modifyData.type, obj: res.data.shop_payment, data: this.modifyData.data }))
      //   this.$nextTick(() => {
      //   // this.modifyData.obj = res.data.shop_payment
      //     this.custName = res.data.shop_payment
      //   })
      // }
    },
    gobackRouter () {
      this.$router.go(-1)
    },
    // 点击操作按钮
    async handleClick (type, data, extraType) {
      if (extraType === 'del') {
        if (data.count.value > 0) {
          this.dialog.delOderItem.formData.quantity = 1
          this.dialog.delOderItem.data = data
          this.dialog.delOderItem.visible = true
        } else {
          this.$message({
            type: 'warning',
            message: this.$t('form.order.productCountLessThanOne')
          })
        }
      }
    },
    handleDelOderItemDialogCancelClick () {
      this.dialog.delOderItem.visible = false
    },
    handleDelOderItemDialogOkClick () {
      this.$refs.delOderItemDialogForm.validate((valid) => {
        if (valid) {
          const data = this.dialog.delOderItem.data
          const requestOption = data.member_actions.custom_actions[0]
          this.$store.state.app.loading = this.$loading(loading)
          request({
            method: requestOption.method,
            url: `/cms/${requestOption.path}`,
            data: {
              [requestOption.extra_params[0].key]: this.dialog.delOderItem.formData.quantity
            }
          }).then(res => {
            this.$message({
              type: 'success',
              message: this.$t('message.operateSuccess')
            })
            this.dialog.delOderItem.visible = false
            this.init()
            // const dataOperate = JSON.parse(sessionStorage.getItem('userOrderdataOperate'))
            // // getDineinOrderDetails(dataOperate.id.value).then(res => {
            // //   this.$store.state.app.loading.close()
            // //   this.dialog.delOderItem.visible = false
            // //   sessionStorage.setItem('userOrderdataOperate', JSON.stringify({
            // //     id: dataOperate.id,
            // //     data: dataOperate.data,
            // //     obj: res.data.shop_payment,
            // //     type: 'view'
            // //   }))
            // //   this.init()
            // // }).catch(() => this.$store.state.app.loading.close())
          }).catch(() => this.$store.state.app.loading.close())
        }
      })
    },
    forwardToPage (data) {
      if (data.model_name && data.model_name === 'host' && data.id) {
        this.$router.push({
          name: 'buddyManagerBuddyDetail',
          params: {
            id: data.id
          }
        })
      }
      if (data.model_name && data.model_name === 'delivery_order' && data.id) {
        this.$router.push({
          name: 'ordersManagerDeliveryOrdersDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    handleOrderItemsTableForwardToPage (data, name) {
      if (name === 'shop_item') {
        this.$router.push({
          name: 'goodsManagerProductDetail',
          params: {
            id: data.id
          }
        })
      } else if (name === 'shop_item_stock') {
        this.$router.push({
          name: 'goodsManagerVariationsDetail',
          params: {
            id: data.id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scope>
.managerUserOrderView {
  .del-oder-item-dialog-body {
    .heder {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .avatar {
        flex-shrink: 0;
      }
    }
    .info-box {
      margin-left: 12px;
      margin-bottom: -12px;
      .title {
        margin-bottom: 12px;
      }
    }
    .notice {
      margin-bottom: 12px;
    }
  }
}
</style>
